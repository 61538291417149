/**
 * Direttiva che crea md-chips per la ricerca di tags
 */

import { IApplicationScope } from "../../shared/global/applicationScope";
import { SenecaResponse } from "atfcore-commonclasses";
import { Tag } from "atfcore-commonclasses";

export interface IfilterChipsDirectiveScope extends IApplicationScope {
    type: string;
    filterPlaceholder: string;
    filteredTags: Array<any>;
    searchTag: Function;
    transformChip: Function;
    addTagToProject: Function;
    addTag: Function;
    removeTagToProject: Function;
    removeTag: Function;
    searchRemoteTagsPromise: any;
    automaticallyOpenOptions: Function;
    selectedFilters: any;
    getFilterPlaceholder: Function;
}

angular.module('app').directive('filterChips', ($window, $timeout, $translate, SearchTagsService, toaster, $rootScope, LibraryApplicationData, $q) => {
    return {
        restrict: 'AEC',
        scope: {
            type: "@",
            selectedFilters: "=",
            addTagToProject: "&",
            removeTagToProject: "&",
            isScormDisabled: '&'
        },
        templateUrl: 'app/shared/filterChips/filterChips.html',
        link: ($scope: IfilterChipsDirectiveScope, element: JQuery, attr: ng.IAttributes) => {
            // Unwrappo le funzioni che aggiungono e rimuovono il tag al progetto, altrimenti non riesco a passare un parametro al controller che chiama la direttiva
            $scope.addTag = $scope.addTagToProject();
            $scope.removeTag = $scope.removeTagToProject();

            angular.element(document).ready(() => {
                let x = element[0].querySelector('input')
                let y = angular.element(x)
            });

            // Lista contenente i tag recuperati con la ricerca
            $scope.filteredTags = [];

            // Imposto i placeholder in base alla tipologia di chip
            $scope.getFilterPlaceholder = () => {
                if ($scope.type == 'langs') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_LANG");
                } else if ($scope.type == 'softSkill') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_SOFT_SKILL");
                } else if ($scope.type == 'techSkill') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_TECH_SKILL");
                } else if ($scope.type == 'topic') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_TOPIC");
                } else if ($scope.type == 'type') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_TYPE");
                }  else if ($scope.type == 'subtype' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_SUBTYPE");
                } else if ($scope.type == 'cluster' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_CLUSTER");
                } else if ($scope.type == 'adminTag' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_ADMIN_TAGS");
                } else if ($scope.type == 'functionalArea' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_FUNCTIONAL_AREA");
                } else if ($scope.type == 'level' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_LEVEL");
                } else if ($scope.type == 'streams' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_STREAM");
                }

                return null;
            }

            $scope.automaticallyOpenOptions = (): any => {
                // Si possono avere al massimo 1 sottotipo, 1 tag cluster, admin tag, functionArea, stream e level. Pertanto non avvio la ricerca se ne ho già selezionato uno
                if (($scope.type == 'cluster' || $scope.type == 'subtype' || $scope.type == 'adminTag' || $scope.type == 'functionalArea' || $scope.type == 'streams' || $scope.type == 'level') && $scope.selectedFilters && $scope.selectedFilters.length) {
                    return null;
                }

                return 0;
            }

            // Effettua la ricerca dei tag in base alla descrizione
            let searchRemoteTags = (tagTitle: string) => {
                return $q((resolve: Function, reject: Function) => {
                    // Preparo i dati calcolati da inviare al server
                    let fromRecord: number = 0, // numero di record da
                        numRecords: number = 50, // a
                        type: string = null // tipo di tag che sto cercando

                    // Verifico che tipo di tag sto cercando
                    if ($scope.type == 'softSkill') {
                        type = LibraryApplicationData.constants.SOFT_COMPETENCES;
                    } else if ($scope.type == 'techSkill') {
                        type = LibraryApplicationData.constants.TECH_COMPETENCES;
                    } else if ($scope.type == 'topic') {
                        type = LibraryApplicationData.constants.ARGUMENTS;
                    } else if ($scope.type == 'cluster') {
                        type = LibraryApplicationData.constants.CLUSTERS;
                    } else if ($scope.type == 'functionalArea') {
                        type = LibraryApplicationData.constants.FUNCTIONAL_AREAS;
                    } else if ($scope.type == 'adminTag') {
                        type = LibraryApplicationData.constants.ADMIN_TAGS;
                    } else if ($scope.type == 'level') {
                        type = LibraryApplicationData.constants.LEVELS;
                    } else if ($scope.type == 'streams') {
                        type = LibraryApplicationData.constants.STREAMS;
                    } else if ($scope.type == 'langs') {
                        type = LibraryApplicationData.constants.LANG;
                    }

                    // Eseguo la ricerca, eliminando le eventuali altre pendenti
                    if ($scope.searchRemoteTagsPromise) {
                        $scope.searchRemoteTagsPromise.$cancelRequest();
                    }
                    $scope.searchRemoteTagsPromise =
                        SearchTagsService.searchTags.query({
                            fromRecord: fromRecord,
                            numRecords: numRecords,
                            title: tagTitle,
                            type: type
                        });
                    $scope.searchRemoteTagsPromise.$promise
                        .then((data: SenecaResponse<Array<any>>) => {
                            if (data.error) {
                                // Dati non validi, quindi alzo l'errore
                                toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                                reject();
                            } else {
                                // Se è presente un ordinamento, li ordino
                                if (data.response && data.response.length) {
                                    for (let i = 0; i < data.response.length; i++) {
                                        let currentResult = data.response[i];
                                        if (currentResult && currentResult.tagAttributes && currentResult.tagAttributes.length) {
                                            for (let k = 0; k < currentResult.tagAttributes.length; k++) {
                                                let currentAttribute = currentResult.tagAttributes[k];
                                                if (currentAttribute.attributeType == LibraryApplicationData.constants.ORDER) {
                                                    currentResult.order = currentAttribute.attributeValue;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    data.response.sort((a: any, b: any) => {
                                        return parseFloat(a.order) - parseFloat(b.order);
                                    });
                                }

                                // Torno i risultati della ricerca
                                resolve(data.response);
                            }
                            // Annullo la promessa
                            $scope.searchRemoteTagsPromise = null;
                        })
                        .catch((error: any) => {
                            // Annullo la promessa
                            $scope.searchRemoteTagsPromise = null;
                            // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                            if (!error || error.config.timeout.$$state.status !== 1) {
                                // Verifico se è un problema di connettività
                                let errorMessage: string = null;

                                // Nuovo oggetto d'errore
                                let newError: any = {
                                    severity: "danger"
                                }

                                if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                    // Problema di connettività
                                    errorMessage = this.$translate.instant("error.generic.NO_SERVER_TITLE");
                                    newError.hideUnknown = true;
                                } else {
                                    // Messaggio di errore generico
                                    errorMessage = this.$translate.instant("error.generic.UNKNOWN_ERROR");
                                }

                                // Imposto il messaggio
                                newError.message = errorMessage;

                                // Lo aggiungo alla lista
                                let errors: Array<any> = [];
                                errors.push(newError);

                                // E apro la modale
                                this.$rootScope.$emit("showApplicationModalErrors", errors);
                            }
                            reject();
                        });
                });
            }

            let searchLocaleTags = (searchedText: string) => {
                // Contenitore di tutti i tag (della topologia) locali
                let localTypeTags = LibraryApplicationData.getItemTypesList();

                // Conenitore degli array filtrati
                let results: Array<any> = [];
                if ((/^\d+$/.test(searchedText))) {
                    results = searchedText ? localTypeTags.filter(
                        (desc: any) => {
                            let regex = new RegExp(searchedText, 'gi');
                            return desc.code.match(regex);
                        }
                    ) : localTypeTags;
                } else {
                    results = searchedText ? localTypeTags.filter(
                        (desc: any) => {
                            let lowercaseQuery = angular.lowercase(searchedText);
                            let regex = new RegExp(lowercaseQuery, 'gi');
                            return desc.desc.match(regex);
                        }
                    ) : localTypeTags;
                }
                return results;
            };

            // Cerca, in locale, la lista dei sottotipi
            let searchLocaleSubtypes = (searchedText: string) => {
                // Contenitore di tutti i tag (della topologia) locali
                let localSubtypeTags = LibraryApplicationData.getItemSubtypesList();

                // Conenitore degli array filtrati
                let results: Array<any> = [];
                if ((/^\d+$/.test(searchedText))) {
                    results = searchedText ? localSubtypeTags.filter(
                        (desc: any) => {
                            let regex = new RegExp(searchedText, 'gi');
                            return desc.code.match(regex);
                        }
                    ) : localSubtypeTags;
                } else {
                    results = searchedText ? localSubtypeTags.filter(
                        (desc: any) => {
                            let lowercaseQuery = angular.lowercase(searchedText);
                            let regex = new RegExp(lowercaseQuery, 'gi');
                            return desc.desc.match(regex);
                        }
                    ) : localSubtypeTags;
                }
                return results;
            };

            $scope.searchTag = (searchedText: string) => {
                let results: any = [];
                if (searchedText && searchedText == '') {
                    // Torno un array vuoto
                    results = $scope.filteredTags;
                } else if ($scope.type == 'type') {
                    // Le chips della tipologia è un elenco predefinito quindi non serve nessuna chiamata al server
                    results = searchLocaleTags(searchedText);
                }  else if ($scope.type == 'subtype') {
                    // Le chips dei sottotipi è un elenco predefinito quindi non serve nessuna chiamata al server
                    results = searchLocaleSubtypes(searchedText);
                } else {
                    // Chiamo il server per recuperare le informazioni
                    results = searchRemoteTags(searchedText);
                }
                return results;
            };

            $scope.transformChip = (chip: any) => {
                return chip;
            }
        }
    };
});